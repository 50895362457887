/*******************************************************************************
					   			Public Pages
*******************************************************************************/

// Sass Variabes

$font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif !default;

$pub-bord-radius: 4px;
$basket-border-radius: 4px;

// Colours
$rn-orange: #ed6a29 !important; // #dd4200
$rn-orange-light: #e4701e;
$rn-blue: #203a5f;
$blue-hover: #2a3d72;
$rn-dark: #262626;
$light-bg: #f5f5f5;
$white: #fff;
$lighter-grey: #d4d4d4;
$mid-grey: #999999;
$dark-grey: #4d4d4d;
$green: forestgreen;
$sem-ui-teal: #00b5ad;
$btf-blue: #162856;

// Social media icon colours

$twitter: #00aced;
$facebook: #3b5998;
$googleplus: #dd4b39;
$pinterest: #cb2027;
$linkedin: #007bb6;
$youtube: #bb0000;
$vimeo: #1ab7ea;
$tumblr: #32506d;
$instagram: #bc2a8d;
$flickr: #ff0084;
$dribbble: #ea4c89;
$quora: #a82400;
$foursquare: #0072b1;
$forrst: #5b9a68;
$vk: #45668e;
$wordpress: #21759b;
$stumbleupon: #eb4823;
$yahoo: #7b0099;
$blogger: #fb8f3d;
$soundcloud: #ff3a00;

// General variables

$box-shadow: 2px 2px 5px $lighter-grey, -2px -2px 5px $lighter-grey;

// Pay Button Effects

$easeOutCust: cubic-bezier(0.03, 0.16, 0.39, 0.99);
$easeInQuad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
$easeOutBack: cubic-bezier(0.175, 0.885, 0.32, 1.275);
$globalDelay: 2;

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opaticy: 1;
  }
}

@keyframes blink {
  0% {
    box-shadow: 0 0 25px #21ba45;
  }
  50% {
    box-shadow: none;
  }
}

// Box and UI Styling
$box-shadow-inset: inset 0px 3px 4px #d8d8d8;
$border-radius: 10px;
$border-radius-sm: 4px;

// Copying over login styles for public error pages

.login-wrapper {
  .login-blk {
    form {
      padding: 0 5%;

      .field {
        input[type="email"],
        input[type="password"] {
          padding-top: 0.8em;
        }
      }

      .checkbox {
        label {
          margin: 0.5rem 0 1rem 0;
        }

        input[type="checkbox"] {
          background: $rn-orange;
          color: $rn-orange;
        }
      }

      label {
        text-align: left;
      }
    }
  }

  .signup-blk {
    padding-left: 2rem !important;
    padding-right: 2rem !important;

    h1.dashed {
      text-align: center;
      padding-bottom: 1.5rem;
      border-bottom: 1px dashed $lighter-grey;
      display: block;
      margin: auto auto 2rem auto;
      //width: 90%;
      color: $rn-orange;
      text-transform: uppercase;
      font-size: 1.2rem;

      span.rn-blue {
        color: $rn-blue;
      }
    }

    label {
      text-align: left;
    }
  }

  .login-logo img {
    max-width: 600px !important;
    width: 80%;
    display: block;
    max-height: 100px;
    margin: 6rem auto;
    //height: 100%;
  }
}

//  Moving these out of public, they shouldnt be limited in that way

.v-top {
  vertical-align: top;
}

.v-middle {
  vertical-align: middle;
}

.v-bottom {
  vertical-align: bottom;
}

.inline-blk {
  display: inline-block;
  *display: inline !important; // IE8 inline-block hack
  *zoom: 1 !important; // IE8 inline-block hack*/
}

.block {
  display: block;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.no-top-margin {
  margin-top: 0 !important;
}

.no-bottom-margin {
  margin-bottom: 0 !important;
}

.less-top-margin {
  margin-top: 1rem;
}

.full-width {
  width: 100%;
}

.focused {
  border: 1px solid $rn-orange;
}

.word-break {
  word-break: break-all;
}

.half-spacer {
  height: 0.5rem;
}

.small-13-text {
  font-size: 13px;
  line-height: 14.85px;
}

.message-banner {
  //min-height: 4rem;
  padding: 1rem 0 2rem 0;
  color: $white;
  font-size: 1.1em;
  z-index: 1001;
  position: fixed;
  display: none;
  width: 100%;

  .message-container {
    max-width: 1024px;
    margin: 0 auto;
    //text-align: center;

    h2 {
      font-size: 1em;
    }

    .error-list {
      list-style: none;

      li.item {
        list-style: none;
      }
    }

    .message-close {
      cursor: pointer;
    }
  }
}

.mid-grey {
  color: $mid-grey;
}

.error-banner {
  background-color: #be0000;
}

.alert-info {
  background-color: #006300;
}

.top-padding {
  padding-top: 3rem;
  //background: $white;
}

.top-margin-6 {
  margin-top: 6px;
}

.three-quarter-wide {
  width: 75%;
  margin: 0 auto !important;
}

.public-container {
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
  display: block;

  hr {
    margin: 1.5rem 0;
    border-top: 1px solid rgba(34, 36, 38, 0.15);
    border-bottom: none;
    border-left: none;
    border-right: none;
    height: 1px;
    display: block;
    width: 100%;
  }
}

.public-container.leaderboard {
  max-width: 10000px;
  width: 100%;

  .org-banner {
    max-width: 1024px;
    margin: 0 auto;
  }

  .tabular.menu {
  }
}

.clearfix {
  clear: both;
}

.word-break {
  word-break: break-all;
}

.word-wrap {
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

.uppercase {
  text-transform: uppercase;
}

.one-col {
  width: 32%;
  padding-left: 0rem;
}

.one-col.col:first-child {
  padding-top: 0;
  padding-left: 0 !important;
}

.one-col:last-child {
  padding-right: 0;
}

.two-col {
  width: 65%;
  padding: 0 !important;
}

.two-col:last-child {
  //padding-top: 0;
}

.three-col {
  width: 100%;
}

.col {
  padding-top: 0;
}

.cols {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  clear: both;
  justify-content: space-around;
  justify-items: flex-start;
  align-content: flex-start;
  column-gap: 2rem;
  box-sizing: border-box;
}

.cols {
  box-sizing: border-box;
  vertical-align: top;

  .col:first-child {
    padding-left: 0;
    //float: left;
    margin-top: 0;
  }

  .col:last-child {
    padding-right: 0;
    //float: right;
    //padding-top: 0;
  }

  > div.clearfix {
    display: none;
  }
}

.padded-blk {
  padding: 2rem;
}

.blk-spacer {
  width: 2rem;
  height: 2rem;
  display: inline-block;
}
// Registration Forms
.chkt-form-container {
  padding: 1.5em;
  margin-bottom: 0em;
  position: relative;
  background: transparent;
  padding: 2rem;
  border-radius: 0 0 $pub-bord-radius $pub-bord-radius;
  border-width: 1px;
  border-style: solid;
  //border-color: rgba(34, 36, 38, 0.15);

  /*.ui.form input:not([type]), .ui.form input[type="date"], .ui.form input[type="datetime-local"], .ui.form input[type="email"], .ui.form input[type="number"], .ui.form input[type="password"], .ui.form input[type="search"], .ui.form input[type="tel"], .ui.form input[type="time"], .ui.form input[type="text"], .ui.form input[type="file"], .ui.form input[type="url"] {
		    font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
		    box-shadow: inset 0px 3px 4px #d8d8d8;
		}
		all.css:28721
		.ui.form textarea, .ui.form input:not([type]), .ui.form input[type="date"], .ui.form input[type="datetime-local"], .ui.form input[type="email"], .ui.form input[type="number"], .ui.form input[type="password"], .ui.form input[type="search"], .ui.form input[type="tel"], .ui.form input[type="time"], .ui.form input[type="text"], .ui.form input[type="file"], .ui.form input[type="url"] {
		    box-shadow: inset 0px 3px 4px #d8d8d8;
		}
		all.css:13422
		.ui.form input:not([type]), .ui.form input[type="date"], .ui.form input[type="datetime-local"], .ui.form input[type="email"], .ui.form input[type="number"], .ui.form input[type="password"], .ui.form input[type="search"], .ui.form input[type="tel"], .ui.form input[type="time"], .ui.form input[type="text"], .ui.form input[type="file"], .ui.form input[type="url"] {
		    font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
		    margin: 0em;
		    outline: none;
		    -webkit-appearance: none;
		    tap-highlight-color: rgba(255, 255, 255, 0);
		    line-height: 1.2142em;
		    padding: 0.67861429em 1em;
		    font-size: 1em;
		    background: #FFFFFF;
		    border: 1px solid rgba(34, 36, 38, 0.15);
		    color: rgba(0, 0, 0, 0.87);
		    border-radius: 0.28571429rem;
		    box-shadow: 0em 0em 0em 0em transparent inset;
		    transition: color 0.1s ease, border-color 0.1s ease;
		}*/
}
.chkt-form-container {
  .yellow-bskt-icon {
    box-shadow: 0px 0px 0px 1px #fbbd08 inset;
    color: #fbbd08;
  }

  .blue-bskt-icon {
    box-shadow: 0px 0px 0px 1px #2185d0 inset;
    color: #2185d0;
  }

  .edit-icons {
    display: inline-block;
    font-size: 0.9rem;
    padding: 0em 0.24em 0.2em 0.24em;
    text-align: center;
    width: 22px;
    height: 22px;
    margin: 0;
    border-radius: 3px;
  }

  small.error {
    display: none;
  }

  small.error.error_text {
    display: none;
  }

  label.required.red {
    small.error:not(small.error.error_text) {
      display: inline-block;
    }

    small.error.error_text {
      display: none !important;
    }
  }

  .selectize-control {
    height: 2.8rem;
  }

  .custom-fields-form {
    .custom-field,
    .field {
      label {
        display: block;

        span,
        input,
        textarea {
          display: block;
        }

        .option span {
          display: inline;
        }
      }

      small {
        display: inline-block;
      }

      small.error {
        display: none;
      }
    }

    .custom-field.required {
      label {
        span {
          display: inline;
        }
      }
    }

    .label_content_span.checkbox {
      display: inline-block !important;
    }

    .custom-field.red,
    .field.red {
      small.error {
        display: inline-block;
      }
      small.error.error_text {
        display: none !important;
      }

      .required {
        small.error {
          display: inline-block;
        }

        small.error.error_text {
          display: none !important;
        }
      }
    }

    .half-width-field {
      width: 47%;
      margin: 0.5rem 3% 1.5rem 0;
      vertical-align: top;

      input {
        width: 100%;
      }
    }

    [data-fieldtype="checkbox"] {
      vertical-align: top;

      label {
        vertical-align: middle;

        span {
          display: inline-block;
          width: 90%;
        }

        input {
          width: auto;
          margin-top: 0.5rem;
          display: inline-block;
          margin-right: 1rem;
          float: left;
        }
      }
    }

    .full-width-field {
      width: 100%;
      display: block;
      margin-bottom: 1.5rem;

      input {
        width: 100%;
      }
    }

    .radios {
      div {
        //width: 30%;
        margin-right: 2.5rem;
        margin-top: 0.25rem;
        display: block !important;

        label {
          display: inline;
          font-weight: normal;
        }

        input {
          width: auto !important;
          margin-right: 0.5rem;
          display: block;
          float: left;
          margin-top: 0.3rem;
        }
      }
    }

    .yes-no {
      div {
        //width: 30%;
        margin-right: 2.5rem;
        margin-top: 0.25rem;

        label {
          display: inline;
          font-weight: normal;
        }

        input {
          width: auto !important;
          margin-right: 0.5rem;
          display: inline-block;
          float: left;
          margin-top: 0.3rem;
        }
      }
    }

    .custom-field,
    .field {
      label.hide-label-after {
        span.label_content_span::after {
          margin: -0.2em 0em 0em 0.2em;
          content: "*";
          color: #db2828;
        }
      }
    }
  }

  .refund-protect-field {
    .radios {
      div {
        //width: 30%;
        margin-right: 2.5rem;
        margin-top: 0.25rem;
        display: block !important;

        label {
          display: inline;
          font-weight: normal;
        }

        input {
          width: auto !important;
          margin-right: 0.5rem;
          display: block;
          float: left;
          margin-top: 0.3rem;
        }
      }
    }
  }

  label[for="termsAgree"]:after,
  label[for="termsAgree"].red:after {
    margin: -0.2em 0em 0em 0em;
    content: "*";
    color: #db2828;
  }

  #entrant-integration-section {
    margin-top: 1em;

    #trainingpartner-section {
      background-color: #d4d4d4;

      .tab {
        padding: 0.5em;
        border: 1px solid #000000;
      }

      .tab.active {
        border: 1px solid $rn-orange;
      }

      .content {
        padding: 0.5em;
        border: 1px solid $rn-orange;
      }
    }
  }

  .terms-container {
    label {
      display: inline-block;
      max-width: 95%;
      vertical-align: top;
    }

    .charity-terms-conditions {
      font-size: 14px;
    }
  }

  .required-fields {
    position: relative;
    top: -1rem;
    color: $mid-grey;
    font-size: 0.95rem;

    span.red {
      color: #db2828;
    }
  }

  .full-width-field.red,
  .half-width-field.red,
  .radios.red,
  .field.red,
  .red {
    position: relative;
    //margin: 1.5rem 0;

    > input,
    > select,
    > textarea,
    .ui.dropdown {
      border: 1px dotted #cc181e !important;
    }

    small.error {
      position: relative;
      background: #cc181e;
      color: white !important;
      padding: 0.3rem;
      border-bottom-left-radius: $pub-bord-radius;
      border-bottom-right-radius: $pub-bord-radius;
      line-height: 1em;
      max-width: 95%;
      left: 6px;
      box-shadow: 2px 2px 5px #666666;
      display: inline-block;
    }

    small.error {
      font-weight: normal !important;
      font-size: 0.9rem;
      margin-top: 0 !important;
      display: inline-block;
    }

    small.error.error_text {
      display: none;
    }
  }

  // Custom Fields v2

  .custom-fields-formv2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-columns: 2fr;
    grid-gap: 2rem;
    margin: 1.5rem 0 3rem 0;
    //grid-template-columns:

    .custom-field {
      margin: 0;

      .checkbox-text {
        display: inline-block;
        margin-left: 0.4rem;
        max-width: 95%;
        vertical-align: top;
      }

      .checkbox-text:first-child {
        margin-top: 0.5rem;
      }

      label {
        margin-bottom: 0.25rem;
        display: inline-block;
        vertical-align: top;

        .label_content_span {
          display: inline;
        }
      }
    }

    .custom-field.full-width-field {
      //grid-column: 1 / 4;
      grid-row: auto;
      //width: 100% !important;
      grid-column-end: span 2;
      margin: 0 !important;
    }

    .custom-field.half-width-field {
      //grid-column: 1 / 2;
      grid-row: auto;
      width: auto !important;

      input:not([type="checkbox"]):not([type="radio"]) {
        width: 100%;
      }
    }

    .two-fields,
    .three-fields {
      display: flex;
      justify-content: space-between;
    }

    .custom-field.full-width-field.required,
    .custom-field.half-width-field.required {
      label::after {
        display: none;
      }

      label:first-child::after {
        display: inline-block;
      }
    }

    .custom-field.full-width-field
      input:not([type="checkbox"]):not([type="radio"]) {
      width: 100%;
    }

    .custom-field.full-width-field input[type="checkbox"],
    .custom-field.full-width-field input[type="radio"] {
      width: auto;
      display: inline-block;
    }
  }

  .refund-protect-field {
    .radios {
      div {
        //width: 30%;
        margin-right: 2.5rem;
        margin-top: 0.25rem;
        display: block !important;

        label {
          display: inline;
          font-weight: normal;
        }

        input {
          width: auto !important;
          margin-right: 0.5rem;
          display: block;
          float: left;
          margin-top: 0.3rem;
        }
      }
    }
  }

  label[for="termsAgree"]:after,
  label[for="termsAgree"].red:after {
    margin: -0.2em 0em 0em 0em;
    content: "*";
    color: #db2828;
  }

  .terms-container {
    label {
      display: inline-block;
      max-width: 95%;
      vertical-align: top;
    }

    .charity-terms-conditions {
      font-size: 14px;
    }
  }

  .required-fields {
    position: relative;
    top: -1rem;
    color: $mid-grey;
    font-size: 0.95rem;

    span.red {
      color: #db2828;
    }
  }

  .full-width-field.red,
  .half-width-field.red,
  .radios.red,
  .field.red,
  .red {
    position: relative;
    //margin: 1.5rem 0;

    > input,
    > select,
    > textarea {
      border: 1px dotted #cc181e !important;
    }

    small.error {
      position: relative;
      background: #cc181e;
      color: white !important;
      padding: 0.3rem;
      border-bottom-left-radius: $pub-bord-radius;
      border-bottom-right-radius: $pub-bord-radius;
      line-height: 1em;
      max-width: 95%;
      left: 6px;
      box-shadow: 2px 2px 5px #666666;
      display: inline-block;
    }

    small.error {
      font-weight: normal !important;
      font-size: 0.9rem;
      margin-top: 0 !important;
      display: inline-block;
    }

    small.error.error_text {
      display: none;
    }
  }

  label.terms-agree-label {
    small.error {
      position: static;
    }

    small:before {
      content: "⇧";
      margin-right: 0.3rem;
    }
  }
}

label.hide-label-after:after {
  display: none !important;
}

.inline-consent-fields {
  margin-top: 0.5rem;

  .consent-field {
    display: inline-block;
    margin-right: 1.5rem;

    label {
      padding-left: 0.25rem;
    }
  }
}

// Associated merchandise cards

.attached_merch_item {
  .image {
    cursor: pointer;
  }

  .variation_select {
    max-width: 100%;
  }
}

.merchandise-options {
  .billing_delivery_address_select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;

    option {
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
    }

    option.new_billing_delivery_address {
      padding: 2rem;
      background: $rn-blue;
      color: $white;
      font-weight: bold;
      display: block;
      width: 100%;
      font-size: 1em;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      line-height: 2rem !important;
      min-height: 3rem !important;
    }

    option.new_billing_delivery_address:before {
      content: "\f044";
      display: inline-block;
      position: relative;
      z-index: 10;
    }
  }
}

.public-reg-form {
  font-size: 1rem !important;
  position: relative;
  top: -1.75rem;

  .first-field {
    margin-top: 1.75rem !important;
  }

  .field:not(.field.half-width-field),
  .full-width-field {
    margin: 0.5rem auto 1.5rem auto;
    width: 100%;
    //display: block;

    input,
    select.half-width-field:not(.dob-fields select) {
      width: 100%;
    }
  }

  .merch-list {
    list-style: none;
    padding-left: 0;

    li {
      margin-left: 0;
      list-style: none;
    }
  }

  .radios {
    //margin-top: 1rem;

    div {
      //width: 30%;
      margin-right: 2.5rem;
      margin-top: 0.25rem;

      label {
        display: inline;
        font-weight: normal;
      }

      input {
        display: inline;
        width: auto !important;
        margin-right: 0.5rem;
      }
    }
  }

  .two-fields {
    //margin: 1rem auto;

    .half-width-field {
      width: 47%;
      margin: 0.5rem 0;
      vertical-align: top;

      input,
      select:not(.dob-fields select) {
        width: 100%;
      }
    }

    .half-width-field:last-child {
      float: none !important;
      margin-left: 5%;
    }
  }

  .dob-fields {
    select {
      width: auto !important;
      display: inline-block;
      margin-right: 0.8rem;
      //height: auto;
      padding-left: 0.25rem;
      padding-right: 0.25rem;
    }

    /*select.dob-day {
				padding-left: 0rem;
				padding-right: 0rem;
			}*/

    select:last-child {
      margin-right: 0;
    }
  }

  .icon-field {
    position: relative;

    i.calendar.icon {
      float: left;
      position: absolute;
      left: 1rem;
      top: 2.2rem;
    }

    input {
      padding-left: 2.5rem !important;
    }
  }

  p,
  p span,
  label {
    font-size: 1rem !important;
  }

  p,
  p span {
    font-family: $font-family;
  }

  p.text {
    font-size: 16px !important;
  }

  label {
    font-weight: bold;
  }

  select,
  input,
  textarea {
    font-size: 1rem;
  }

  select {
    height: 2.8rem;
    display: block;
    width: 100%;
    background: #ffffff;
    border: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 0.28571429rem;
    box-shadow: 0em 0em 0em 0em transparent inset;
    padding: 0.62em 1em;
    color: rgba(0, 0, 0, 0.87);
    transition: color 0.1s ease, border-color 0.1s ease;
  }

  .all-select {
    input.search {
      padding-left: 1rem !important;
    }
  }

  select.not-sui-select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: $border-radius-sm;
    position: relative;
    z-index: 11;
    transition: 0.3s linear all;
    background: transparent;
  }

  select.not-sui-select[name="donation_nudge"] {
    z-index: 0;
  }

  select.not-sui-select:focus,
  select.not-sui-select:active {
    border: 1px solid $rn-orange;
  }

  select.not-sui-select:hover {
    border: 1px solid black;
    cursor: pointer;
    transition: 0.3s linear all;
  }

  i.not-sui-select-icon {
    //display: inline-block;
    position: relative;
    left: -6px;
    top: 32px;
    margin-top: -24px;
    z-index: 12;
    float: right;
    font-size: 0.9em;
  }

  .results-table-wrapper {
    border-radius: $pub-bord-radius;
    box-shadow: $box-shadow;
    position: relative;
    overflow-y: auto;
    max-height: 150px;
    margin-top: -5px;
  }

  .results-table {
    border-radius: $pub-bord-radius;
    width: 100%;
    border: 1px $mid-grey solid;
    border-collapse: separate;

    thead {
      tr {
        background: $lighter-grey;

        th {
          text-align: left;
          padding: 0.5rem 1rem;
        }
      }
    }

    tbody {
      tr:nth-child(even) {
        background: $light-bg;
      }

      tr {
        td:first-child {
          font-weight: bold;
        }
      }

      td {
        text-align: left;
        padding: 0.5rem 1rem;
      }

      td:first-child {
        width: 70%;
        //border-right: 1px solid $mid-grey;
      }
    }
  }

  .results-table.search {
    border-radius: 0 0 $pub-bord-radius $pub-bord-radius;
    border: none;

    /*th:first-child {
				border-top-left-radius: 0;
			}

			th.last-child {
				border-top-right-radius: 0;
			}

			tr:last-child {

				td:first-child {
					border-bottom-left-radius: $pub-bord-radius;
				}

				td:last-child{
					border-bottom-right-radius: $pub-bord-radius;
				}
			}*/
  }

  .pub-action-input {
    margin-bottom: 1rem;

    input[type="text"],
    select,
    button {
      height: 38px;
      margin: 0 -0.3rem 1rem 0;
    }

    input[type="text"] {
      width: 50%;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    select {
      width: auto;
      display: inline-block;
      border-radius: 0;
    }

    button {
      padding: 0.5rem 1rem;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      width: 94px;
    }
  }

  span.prefix,
  label.prefix {
    background: #f2f2f2;
    border: none;
    height: 2.51rem;
    position: absolute;
    top: 2.575rem;
    width: 2rem;
    border-radius: $pub-bord-radius 0 0 $pub-bord-radius;
    text-align: center;
    left: 1px;
    padding-top: 0.5rem;
    margin-top: -2.5rem;
  }

  .relative {
    input {
      padding-left: 2.4rem !important;
    }
  }

  input:not([type]),
  input[type="text"],
  input[type="email"],
  input[type="date"],
  input[type="number"],
  input[type="password"] {
    box-shadow: inset 0px 3px 4px #d8d8d8;
    margin: 0em;
    outline: none;
    -webkit-appearance: none;
    tap-highlight-color: rgba(255, 255, 255, 0);
    line-height: 1.2142em;
    padding: 0.67861429em 1em;
    background: #ffffff;
    border: 1px solid rgba(34, 36, 38, 0.15);
    color: rgba(0, 0, 0, 0.87);
    border-radius: 0.28571429rem;
    transition: color 0.1s ease, border-color 0.1s ease;
    vertical-align: top;
  }

  textarea,
  textarea:focus {
    box-shadow: inset 0px 3px 4px #d8d8d8;
    background-color: white;
    margin: 0em;
    -webkit-appearance: none;
    tap-highlight-color: rgba(255, 255, 255, 0);
    padding: 0.78571429em 1em;
    background: #ffffff;
    border: 1px solid rgba(34, 36, 38, 0.15);
    outline: none;
    color: rgba(0, 0, 0, 0.87);
    border-radius: 0.28571429rem;
    transition: color 0.1s ease, border-color 0.1s ease;
    line-height: 1.2857;
    resize: vertical;
    vertical-align: top;
    width: 100%;
  }
}

.sg-logo {
  width: 150px;
  max-width: 50%;
  height: 50px;
  top: -3.42rem;
  left: 0rem;
  margin-bottom: -3.42rem;
  background: #f5f5f5;
  //padding: 1rem 2rem 0.2rem 2rem;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  position: relative;
  z-index: 2;
  border-top: 2px solid $mid-grey;
  border-left: 2px solid $mid-grey;
  border-right: 2px solid $mid-grey;
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

img.sg-logo-img {
  width: 100px;
  max-width: 50%;
  position: relative;
  z-index: 4;
  top: -2.2rem;
  left: 8.9rem;
  float: right;
}

.charity-field {
  clear: both;
  margin-top: -1.5rem !important;
}

.sg-nudge-block {
  position: static;

  label {
    font-size: 0.95rem !important;
  }

  h3 {
    margin: 0 0 1rem 0;
  }

  label[for="donation_nudge_giftaid"] {
    margin-bottom: 0.5rem;
    display: inline-block;
  }

  h3 {
    margin: 0 0 1rem 0;
  }

  h3.fundraising-header {
    margin: -0.5rem auto 2.5rem auto;
  }

  /*h3:nth-child(2) {
>>>>>>> master
			margin-top: -1.5rem;
		}*/

  .mask-block {
    width: 146px;
    max-width: 49%;
    float: right;
    position: absolute;
    top: -0.5rem;
    right: 0;
    height: 1rem;
    z-index: 4;
    background: $light-bg;
  }

  label[for="termsCharity"] {
    font-weight: normal;
  }

  .donate-now-header {
    margin: 1rem 0 0.5rem 0;
  }

  .not-sui-select {
    background: $white !important;
  }

  .inline-consent-fields {
    label {
      font-weight: normal;
    }
  }

  i.not-sui-select-icon.dropdown.icon {
    position: absolute;
    right: -10px;
    left: 96%;
    top: 90%;
  }

  .donation-nudge-lsds {
    margin-top: 1rem;
  }
}

.sg-nudge-block.temp-shadow,
.sg-logo.temp-shadow {
  //box-shadow: 1px 1px 2px $rn-blue, -1px -1px 2px $rn-blue, 1px -1px 2px $rn-blue, -1px 1px 2px $rn-blue;
  //border: none !important;
  //background: $lighter-grey !important;
  border: 2px solid $rn-blue !important;
}

.sg-nudge-block.persist {
  background: $light-bg;
  padding: 0.5rem 1.5rem 1.5rem 1.5rem;
  border-top-left-radius: 6px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border: 2px solid $mid-grey;
  display: block;
  transition: all 0.25s ease;
}

.donation-nudge {
}

.donation-nudge-giftaid {
  padding-top: 2rem;

  p {
    font-size: 0.9rem !important;
    color: $rn-dark;
  }
}

#btf-racepass-section {
  border: 2px solid #162856;

  .btf-logo-container {
    top: -1rem;
    right: -0.5rem;
  }

  .btf-blue {
    color: $btf-blue;
  }
}

body.down .mobile-cta-footer.clone {
  bottom: 0;
}

/*body.down {

	footer.public-footer {
		margin-bottom: 50px !important;
	}
}*/

.mobile-cta-footer,
.mobile-main-menu {
  display: none;
}

// Potentially to be moved to its own Sass but we'll plant it here for now

body,
html {
  height: 100%;
  margin: 0;
}

body.public.animating.in.dimmable,
body.public.dimmed.dimmable {
  overflow: scroll;
  overflow-x: hidden;
}

// Ticket description tool tip

.tooltipster-sidetip .tooltipster-box {
  max-width: 300px !important;
}

.merch-item-modal {
  .ui.image img {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .content {
    p {
      text-align: left;
    }
  }

  .image.content {
    display: flex !important;
  }

  .item-desc {
    font-size: 1.2rem;

    p {
      text-align: left !important;
    }
  }

  .merch-price {
    font-size: 1.3rem;
    font-weight: bold;
    text-align: left !important;
    margin-top: 1rem;
  }

  .adding-to-bskt {
    display: none;
  }

  .description {
    .modal-merch-select {
      height: 2rem;
    }
  }
}

body.public .main .basket .sub-step {
  margin-bottom: 0;
  margin-top: -0.1rem;
}

body.public {
  background: $light-bg;
  position: relative;
  font-family: $font-family;
  font-size: 16px;
  margin: 0;
  box-sizing: border-box;

  .pusher {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  // Public custom classes

  .ios-app-dl-btn {
    overflow: hidden;
    border-radius: 13px;
    width: 35%;
    height: auto;
    margin: 0 auto;
    display: block;

    img {
      border-radius: 13px;
      width: 100%;
    }
  }

  .no-top-padding {
    padding-top: 0 !important;
  }

  .no-bottom-padding {
    padding-bottom: 0;
  }

  .no-left-padding {
    padding-left: 0;
  }

  .no-left-margin {
    margin-left: 0 !important;
  }

  .less-top-padding {
    padding-top: 0.5rem;
  }

  .less-bottom-padding {
    padding-bottom: 0.5rem;
  }

  .light-colour {
    color: $white !important;
  }

  .italic {
    font-style: italic;
  }

  .larger {
    font-size: 1.1em !important;
    padding: 0.6em 1.1em;
  }

  .center-aligned {
    text-align: center;
    margin: 0 auto;
  }

  .wide-btn {
    width: 180px;
  }

  .event-info-container {
    .event-content {
      display: block !important;
      max-height: 400px;
      overflow-y: auto;

      p {
        padding-bottom: 1rem;
      }
    }

    .header {
      span {
        display: block;
      }
    }

    .event-info-date {
      margin: 0;
      font-size: 1rem;
      color: $mid-grey;
      line-height: 1.2rem;
      font-weight: 400;
    }

    img {
      border-radius: $pub-bord-radius;
      max-width: 100%;
      height: auto !important;
    }

    ul {
      margin-bottom: 1rem;

      li {
        margin-left: 1rem;
      }
    }
  }

  button,
  input[type="submit"] {
    border-radius: $pub-bord-radius;
    padding: 0.5rem 1rem;
    font-size: 0.8rem;
    font-weight: bold;
    border: none;
  }

  .event-info-btn {
    border-radius: 3px;
    padding: 0.2rem 0.5rem 0 0.5rem;
    font-size: 0.8rem;
    font-weight: bold;
    border: none;
    margin-bottom: 0.5rem;
    min-height: 2rem;
    cursor: pointer;
  }

  .ui.popup.wide {
    min-width: 1024px;
    border-style: solid;
    border-width: 1px;

    p {
      text-align: left;
    }
  }

  // Header

  header.public-header {
    background: #242424;
    width: 100%;
    height: auto;
    min-height: 52px;
    position: fixed;
    top: 0;
    z-index: 1001;

    .public-container {
      display: flex;
      justify-content: space-between;
      align-items: stretch;

      .org-title {
        color: $rn-dark;
        text-align: left;
        text-transform: capitalize;
        margin-right: auto;
        align-self: center;
      }

      .lang-container {
        margin-right: 4%;

        .lang-selector {
          background: $lighter-grey;
          color: $rn-dark;
          margin-top: 0.7rem;
          border: 1px solid $rn-dark;
        }

        .lang-selector.light-colour {
          border: 1px solid $white;
        }
      }

      .powered-by {
        text-align: right;
        align-self: center;
        padding-top: 0.5rem;
      }

      .basket-menu {
        align-self: center;
        display: none;
        display: -webkit-inline-flex;
        font-size: 1.2rem;
        top: 5px;
        right: 5px;
        margin-left: 4%;
      }
    }
  }

  header.public-header.leaderboard-header {
    .public-container {
      max-width: 100% !important;

      .public-container .org-title {
        margin-top: -12px !important;
      }
    }
  }

  // Messages Display

  .ui.message {
    .notify-bar-close {
      float: right;
      font-size: 1.5rem;
    }
  }

  .variation-price {
    span {
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;
    }

    span.updated {
      //color: $rn-orange;
      font-weight: bold;
    }
  }

  // Main body content

  .main.invite-register {
    .public-accordion {
      width: 100%;
    }
  }

  .main {
    background: $white;
    clear: both;
    //box-shadow: -2px 0 3px $lighter-grey, 2px 0 3px $lighter-grey;
    padding: 5rem 2rem 2rem 2rem;
    display: block;
    min-height: 920px;

    .rn-blue-bg {
      background: $rn-blue;
    }

    .ui.styled.accordion {
      width: 100% !important;
    }

    img {
      border-radius: $pub-bord-radius;
      max-width: 100%;
      height: auto !important;
    }

    .org-banner {
      text-align: center;

      img {
        margin: 1rem auto;
        width: 100%;
      }
    }

    .promo-img {
      margin-top: 2rem;

      img {
        width: 100%;
      }
    }

    .org-intro {
      margin: 2rem 0;
    }

    .desc,
    .custom_content {
      word-wrap: break-word;
      -webkit-hyphens: auto;
      -moz-hyphens: auto;
      -ms-hyphens: auto;
      hyphens: auto;
      //min-height: 200px;

      ol,
      ul {
        margin-bottom: 1rem;

        li {
          margin-left: 2rem;
        }
      }
    }

    .org-intro.org-page {
      margin-top: 0;
    }

    .terms-link-text {
      font-size: 1rem;
    }

    .register-breadcrumb {
      margin-top: 0.5rem;
      margin-bottom: 1rem;
      width: 100%;

      .bread-item {
        padding: 0.2rem 1rem 0 1rem;
        font-weight: bold;
        font-size: 0.8rem;
        border-radius: $pub-bord-radius;
        background: #999999;
        margin-bottom: 0.5rem;

        a {
          text-decoration: none;
        }

        a:hover {
          text-decoration: underline;
        }
      }

      .arrow {
        font-size: 1.4rem;
        color: #999999;
        margin-bottom: 0.5rem;
      }
    }

    .add-tickets {
      .category-wrapper:first-of-type {
        .title {
          margin-top: 0 !important;
        }
      }
      .category-wrapper {
        .title {
          -webkit-box-shadow: inset 0px 0px 0px 1px $lighter-grey;
          -moz-box-shadow: inset 0px 0px 0px 1px $lighter-grey;
          box-shadow: inset 0px 0px 0px 1px $lighter-grey;
          margin-bottom: 0 !important;
          margin-top: 1rem !important;
        }

        .title.active {
          -webkit-box-shadow: none;
          -moz-box-shadow: none;
          box-shadow: none;
          background-color: $lighter-grey;
        }

        .content {
          //margin-top: -1rem;
          -webkit-box-shadow: inset 0px -1px 0px 1px $lighter-grey;
          -moz-box-shadow: inset 0px -1px 0px 1px $lighter-grey;
          box-shadow: inset 0px -1px 0px 1px $lighter-grey;
          border-top-left-radius: 0px !important;
          border-top-right-radius: 0px !important;
          margin-bottom: 1rem !important;

          article.text {
            font-size: 0.8em;
            padding: 0 0.5rem 1rem 0.5rem;
            border-bottom: 1px dotted $lighter-grey;
            margin-bottom: 0.5rem;
          }
        }

        .ticket-row {
          border-top: 1px dotted $lighter-grey;
          padding-top: 0.5rem;
        }

        .ticket-row.has-tickets:first-of-type,
        .ticket-row:not(.empty):first-of-type,
        .ticket-row.empty {
          border-top: none;
        }

        .ticket-items {
          .divider {
            display: none;
          }
        }
      }

      .ticket-items {
        //margin-bottom: 0.7rem;
        //padding-bottom: 0.7rem;
        vertical-align: middle;
        border-bottom: none;
        padding-bottom: 0;

        .item-title {
          padding-top: 0.3rem;
          margin-bottom: 1rem;
          max-width: 480px;
          hyphens: none !important;
          word-wrap: normal !important;

          strong {
            margin-right: 0.5rem;
          }

          .desc-btn.btn {
            padding: 0 0 1.4em 0.13em;
            text-align: center;
            display: inline-block;
            font-weight: normal;
            max-width: 1.5rem;
            max-height: 1.5rem;
            margin-right: 0.5rem;
            cursor: pointer;

            i.icon {
              font-size: 0.98rem;
            }
          }
        }

        .price-title {
          padding-top: 0.3rem;
        }

        .divider {
          border-bottom: 1px solid $lighter-grey;
          margin: 0 auto 1.5rem auto;
        }
      }

      .ticket-items:last-child {
        border-bottom: none;
        margin-bottom: 0;
        //padding-bottom: 0;
      }
    }

    .buy-btn {
      text-align: right;
      float: right;
      clear: both;
      margin-top: 1rem;

      .add-button {
        height: 1.8rem;
        cursor: pointer;
      }
    }

    .events {
      .border-wrapped {
        hr:last-child {
          display: none;
        }
      }
    }

    .charity-blk {
      img {
        width: 100%;
        max-width: 500px;
      }

      .charity-chunk {
        font-size: 0.9em;
      }

      hr:last-child {
        display: none;
      }
    }

    .public-btn {
      padding: 1rem 1.5rem;
      color: $white;
      background: $rn-dark;
      font-weight: bold;
      text-align: center;
      border-radius: $pub-bord-radius;
      cursor: pointer;
    }

    .finish-reg-btn {
      background-color: $green !important;
    }

    // Ballots

    .ballot-tickets {
      display: flex;
      align-content: flex-end;
      justify-content: space-between;
      //column-gap: 1.25rem;

      ul {
        margin: 0 auto 0 1rem;
      }

      div {
        align-self: center;
      }
    }

    .ballot-wrapper {
      border-bottom: 1px solid $lighter-grey;

      .ballot-block {
        border-bottom: 1px dotted $mid-grey;
        padding-bottom: 1rem;

        h3:first-child {
          margin-top: 1rem;
        }

        .price-col {
          min-width: 86px;
          text-align: right;
          margin-right: 1.25rem;
        }

        .add-ballot-btn {
          min-width: 65px;
        }
      }

      .ballot-block:last-child {
        border-bottom: 0;
      }

      .ballot-tickets-list {
        list-style: none;

        li.ballot-ticket-item {
          list-style: none;
          margin-bottom: 0.5rem;
          display: flex;
          align-content: center;
          justify-content: space-between;
          //column-gap: 1.25rem;
          border-bottom: 1px dashed $lighter-grey;
          padding-bottom: 0.5rem;

          div {
            align-self: baseline;
          }

          .ballot-item-title {
            margin: 0 auto 0 0;
          }
        }

        li.ballot-ticket-item:last-child {
          border-bottom: none;
        }
      }

      .ballot-tickets-list:last-child {
        border-bottom: none;
      }

      .ballot-tickets-list,
      .ballot-ticket-item {
        div:last-child {
          min-width: 65px;
        }
      }
    }

    .public-accordion {
      .ballot-info-accordion {
        margin-bottom: 1.5rem;

        .content {
          font-size: 1rem;
        }
      }

      .ballot-info-title {
        font-weight: normal;
        font-size: 1rem;
        background: initial;
        border-radius: initial;
        padding: 0.5rem;
        margin-bottom: initial;
        transition: initial;
      }

      .title:not(.ballot-info-title) {
        font-size: 17px;
        //border-bottom: 1px solid $lighter-grey;
        background: $light-bg;
        border-radius: $pub-bord-radius;
        padding: 12px;
        margin-bottom: 0.5rem;
        cursor: pointer;
        display: block;
        transition: all linear 0.1s;

        .title-content {
          display: inline-block;
          max-width: 88%;
        }

        span {
          display: inline-block;
          vertical-align: top;
          float: right;

          > i {
            float: right !important;
            -webkit-transform: rotate(270deg);
            -moz-transform: rotate(270deg);
            filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
            transform: rotate(270deg);
            transition: all linear 0.1s;
          }

          > i.rotated {
            -webkit-transform: rotate(360deg);
            -moz-transform: rotate(360deg);
            filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=-1);
            transform: rotate(360deg);
          }
        }
      }

      .not-rotated {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0);
        transform: rotate(0deg);
        //transition: all linear 0.1s;
      }

      .content:not(.ballot-info-content) {
        background: #f9f9f9;
        margin-bottom: 0.5rem;
        padding: 1rem;
        border-radius: $pub-bord-radius;
        width: 100% !important;
      }

      .content.active {
        .ticket-items:last-child {
          border-bottom: none;
          margin-bottom: 0;
          //padding-bottom: 0;

          .divider {
            //display: none !important;
          }
        }
      }
    }

    .cats {
      //margin-top: 2rem;

      .cat {
        margin-bottom: 1rem;
        font-size: 1.2rem;
        list-style: none;
        margin-right: 1rem;
        display: inline-block;

        a {
          padding: 0.5rem;
          border-radius: 3px;
          color: $rn-dark;
          font-size: 1rem;
        }

        a:before {
          content: "";
        }
      }
    }

    ul.series-list {
      li {
        padding: 0.75rem 0;
        border-bottom: 1px solid $lighter-grey;

        .less-top-padding {
          max-width: 70%;
        }
      }

      li:last-child {
        border-bottom: none;
      }
    }

    .org-contact-list {
      list-style: none !important;
      list-style-type: none;
      list-style-image: none;
      padding-left: 0;
      margin-left: 0;
      width: 100%;

      li.contact-item {
        list-style: none !important;
        list-style-image: none;
        list-style-type: none;
        margin-bottom: 0.5rem;
        word-break: break-all;
        hyphens: auto;
        font-weight: bold;
        font-size: 0.9em;
        padding-left: 0;
        margin-left: 0;
        white-space: normal;

        i {
          font-size: 1.2em;
        }

        span {
          font-weight: normal;
          /*display: inline-block;
					max-width: 75%;
					vertical-align: top;*/

          a:link {
            text-decoration: underline;
          }
        }
      }

      li:before {
        background: none;
        content: none;
      }
    }

    .container--heading-link {
      display: block;
      color: #183c73 !important;
    }

    .merch-title-btn {
      /*display: inline-block;
			padding: 0.5rem 1rem;*/
      border: 1px solid $light-bg !important;
      //border-radius: $pub-bord-radius;
    }

    .merch {
      margin-bottom: 2rem;
    }

    .merchandice-container {
      margin-top: 2rem;

      .content.cols {
        padding: 1rem 0.5rem 1rem 0;
      }
    }

    .merch-items {
      margin: 0 auto;
      text-align: center;
      overflow: hidden;
      max-width: 600px;
      //float: left;
      display: block;
      width: 100% !important;

      li.merch-item {
        text-align: center;
        padding: 1rem 0.5rem;
        border: 1px solid #f1f1f1 !important;
        background: $white;
        border-radius: $pub-bord-radius;
        margin: 0 0.5rem;
        list-style: none;
        position: relative;
        width: 180px;
        min-width: 180px !important;
        height: 340px !important;
        transition: all linear 0.3s;

        .merch-title {
          display: block;
          cursor: pointer;
        }

        .merch-title:hover {
          opacity: 0.85;
          text-decoration: underline;
        }

        select.merch-select {
          padding: 0.5rem auto;
          height: 1.8rem;
          font-size: 1rem;
          line-height: 1.8rem;
          max-width: 100%;

          option {
            vertical-align: middle;
            padding: 0.25rem auto;
          }
        }

        img {
          width: auto;
          max-width: 98%;
          max-height: 150px;
          margin: 0.5rem auto;
          transition: all linear 0.2s;
          //border: 1px solid $lighter-grey;
          cursor: pointer;
        }

        img:hover {
          opacity: 0.85;
        }

        .merch-price {
          display: block;
          clear: both;
          margin: 0.5rem auto;
        }

        .merch-item-bottom {
          position: absolute;
          bottom: 0.5rem;
          left: 0;
          right: 0;
          text-align: center;
          height: 75px;

          button {
            display: block;
            width: 90px;
            margin: 1rem auto 0 auto;
            padding: 0.5rem 0.5rem;
          }
        }

        .merch-item-bottom.variations {
          height: 100px;
        }
      }
    }

    .prev-button,
    .next-button {
      display: none;
      width: 26px;
      height: 30px;
      line-height: 1;
      float: right;
      outline: none;
      margin-top: 1rem;
      padding: 0.5rem 1rem 0.5rem 0.5rem;
      cursor: pointer;
    }

    .prev-button {
      //margin-left: -0.5rem;
    }

    .next-button {
      margin-left: 0.5rem;
    }

    .border-wrapped {
      padding: 1.2rem;
      border-style: none solid solid solid;
      border-width: 1px;
      border-bottom-left-radius: $pub-bord-radius;
      border-bottom-right-radius: $pub-bord-radius;
      margin-top: 0;
    }

    .border-wrapped.no-padding {
      padding: 0 !important;
    }

    .border-wrapped-header {
      background: $lighter-grey;
      padding: 0.6rem 1.2rem;
      //position: relative;
      //bottom: -2rem;
      //margin-top: 0;
      font-size: 1.2rem;
      border-width: 1px 1px 0 1px;
      border-style: solid;
      border-color: $rn-dark;
      border-top-left-radius: $pub-bord-radius;
      border-top-right-radius: $pub-bord-radius;
      margin-bottom: 0;
      margin-top: 2rem;
      color: $rn-dark;
      width: 100%;
      position: relative;
      z-index: 6;
    }

    .border-wrapped-header.about-org {
      margin-top: 0;
    }

    .border-wrapped-header.bskt-header {
      margin-top: 0;
    }

    .checkout-btn,
    .btn {
      border-radius: $border-radius-sm;
      border: none;
      background: $lighter-grey;
      padding: 0.5rem 1rem;
      color: $rn-dark;
      font-weight: bold;
    }

    .btn.centered {
      margin: 0 auto;
      width: auto;
      display: block;
      text-align: center;
    }

    .checkout-btn.bskt {
      width: 100%;
      text-align: center;
      padding: 0.7rem 1rem;
      height: 42px;

      i.icon {
        font-size: 0.9em;
      }
    }

    .donate-btn {
      //width: 75%;
      margin: 1rem auto 0 auto;
      text-align: center;
      display: block;
      //float: right;
    }

    .merch-wrapper {
      text-align: center !important;
      //padding-right: 1rem !important;
    }

    /******************************************************************
						New basket styling (Addded March 2020)
		******************************************************************/

    .basket.receipt-items {
      .entry_head:first-child {
        border-top: 1px solid $dark-grey !important;
      }
    }

    .basket {
      position: relative;
      background: $white;
      border: 1px solid #262626;
      border-bottom-right-radius: $basket-border-radius;
      border-bottom-left-radius: $basket-border-radius;

      .entry_head:not(.team_entry),
      .team_head,
      .series_head,
      .others_head {
        display: flex;
        display: -webkit-flex;
        justify-content: flex-end;
        grid-column-gap: 0.5rem;
        //flex-wrap: wrap-reverse;
        padding: 0.75rem;
        background: $light-bg;
        font-weight: bold;
        border-top: 1px solid $dark-grey;
        align-items: center;
        position: relative;
        z-index: 5;

        .step-left {
          margin-right: auto;

          .entrant_name {
            font-size: 0.8em;
          }
        }
      }

      .active.entry_head {
        font-weight: bold;
      }

      .active.entry_head.team_entry::before {
        content: "\2039";
        font-size: 1.5rem;
        position: relative;
        bottom: 0.1rem;
      }

      .series_head ~ .entry_head {
        border-top: none;
      }

      .series_head {
        height: auto;
        padding-bottom: 0.5rem;
      }

      .active-item::before {
        display: block;
        position: relative;
        z-index: 2;
        content: "";
        top: 0.2rem;
        //margin-top: -1rem;
        margin-bottom: -1.4rem;
        left: 0;
        border: medium none;
        background-color: $lighter-grey;
        width: 1.14285714em;
        height: 1.14285714em;
        border-style: solid;
        //border-color: rgba(34, 36, 38, 0.15);
        border-width: 1px 0px 0px 1px;
        transition: background-color 0.1s ease, opacity 0.1s ease,
          color 0.1s ease, box-shadow 0.1s ease;
        transform: translateY(50%) translateX(-50%) rotate(-45deg);
        box-shadow: -2px 0 8px 4px $lighter-grey;
      }

      .active.entry_head::before,
      .active.team_head::before,
      .active.series_head::before,
      .active.others_head::before {
      }

      .active.entry_head:not(.team_entry):not(.series_entry),
      .active.team_head,
      .active.series_head,
      .active.others_head {
        background-color: $lighter-grey;
      }

      .entry_head:first-child {
        border-top: none;
      }

      .entry_head.series_entry {
        background: $white;
      }

      .active.entry_head.series_entry {
        background: $light-bg;
      }

      .team_foot,
      .series_foot {
        font-size: 0.9em;

        .team_total {
          background: #f0f0f0;
        }

        .item_row {
          padding: 0.25rem 0.5rem;
        }
      }

      .series_head {
        padding-bottom: 0.75rem;

        .step-left {
          width: auto;
        }
      }

      .series_entry {
        border-bottom: 1px solid $light-bg;
      }

      .series_foot {
        display: flex;
        display: -webkit-flex;
        border-top: $mid-grey 1px dotted;
        padding: 0.5rem 0.75rem;
        font-weight: bold;
      }

      .step-left {
        width: 70%;
        margin-right: auto;
      }

      .step-left.sub-item {
        width: 70%;
        max-width: 260px !important;
        padding: 0 !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: auto;
      }

      .step-accom {
        padding: 0.25rem;
        text-align: center;
        max-width: 46%;
        min-width: 194px;

        .accom-text {
          font-size: 0.8rem;
          line-height: 1rem;
        }

        .ui.icon.button.accom-btn {
          width: initial !important;
          height: auto !important;
          padding: 0.589286em;
        }
      }

      .step-left.sub-item:before {
        content: "+ ";
        color: $dark-grey;
        font-weight: bold;
        font-size: 0.8em;
        position: relative;
        bottom: 0.1rem;
      }

      .step-right {
        text-align: right;
        justify-content: flex-end;
      }

      .step-left,
      .step-right {
        small {
          color: $dark-grey;
        }
      }

      .remove-from-bskt {
        position: inherit !important;
      }

      .item_row,
      .team_entry {
        display: flex;
        display: -webkit-flex;
        justify-content: flex-end;
        grid-column-gap: 0.5rem;
        padding: 0.25rem 0.75rem;
        position: relative;
        border-bottom: 1px solid $light-bg;

        .desc-btn.basket-popup {
          cursor: pointer;
          color: $rn-blue;
          position: relative;
          right: 1rem;
        }
      }

      .team_block_accordion {
        border-radius: 0;
        border: none;

        .title {
          font-size: 0.9em;
          padding-left: 0.5rem;
        }

        .content {
          padding: 0;
          font-size: 1rem;
        }
      }

      .item_row {
        //margin-top: 0.25rem;
        //min-height: 1.6rem;

        > div:last-child {
          margin-left: 0.5rem;
        }
      }

      .team_entry {
        border-bottom: $mid-grey 1px dotted;
        padding: 0.5rem 0.75rem;
      }

      .team_entry:last-child {
        border-bottom: none !important;
      }

      .other_item {
        padding: 0.5rem 0.75rem;
        border-top: $mid-grey 1px dotted;
        align-items: center;
      }

      .other_item:first-child {
        border-top: none;
      }

      .basket-item-actions {
        padding-bottom: 1px;
      }

      .bskt-edit-btn {
        padding: 0.3em 0.24em;
        height: 22px !important;
      }

      .ui.icon.button {
        padding: 0.4em 0.24em;
        width: 22px;
        height: 20px;
        margin: 0;
      }

      hr {
        margin: 0.25rem 0 0 0 !important;
      }

      .bskt-total {
        border-top: 1px solid $rn-blue;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: $pub-bord-radius;
        border-bottom-right-radius: $pub-bord-radius;
        padding: 0.75rem;
      }
    }

    .basket-container {
      background: $light-bg;
      border-radius: $pub-bord-radius;
      margin-top: 2rem;
      //padding: 1rem 2rem 2rem 1rem;

      h5.organiser-bskt {
        font-size: 1.2rem;
        margin-bottom: 2rem;
        //color: $mid-grey;
      }

      /*
			.basket-item {

				.basket-event-item {
					margin: 1rem 0;
					border-top: 1px solid $lighter-grey;
					border-bottom: none !important;
					padding-top: 0.5rem;
					padding-bottom: 0;

					.title {
						max-width: 66%;
						line-height: 1.2rem;
						padding-top: 0.5rem;
					}

					.title.sub-item {
						font-size: 0.9em;
						position: relative;
						top: 0.2rem;
					}

					.price {
						padding-top: 0.5rem;
					}

				}

				.basket-event-item.is-sub {
					border-top: none !important;
					margin-top: -1.6rem;

					.title {
						font-size: 0.9em;
						position: relative;
						top: 0.2rem;

						strong {
							font-weight: normal;
						}
					}
				}

				.basket-event-item:first-child {
					border-top: none !important;
					padding-top: 0;
				}

			}
			*/

      .remove-btn {
        display: inline-block !important;

        .item-remove-btn {
          display: inline;
          box-sizing: border-box;
          padding: 0rem 0 1.6rem 0.1rem;
          font-size: 1rem;
          //max-width: 20px;
          //max-height: 20px;
          background: transparent;
          color: $mid-grey;

          i {
            display: block;
            height: 1rem;
          }
        }

        .item-remove-btn:hover {
          color: #d64842;
        }
      }

      /*.item-remove-btn:hover {
				background: red;
				color: white;

			}*/

      .item-info {
        max-width: 90%;
      }
    }

    // Checkout overlay.
    .order {
      &--checkout-summary {
        color: $rn-dark;
        margin-bottom: 3%;
      }
    }

    .entry-below {
      text-align: center;
    }

    .checkout-total {
      font-size: 1.4rem;

      span {
        line-height: 1.5em;
        display: inline-block;
        vertical-align: bottom;
        //padding-bottom: 0.25rem;
      }

      .sub-total {
        font-size: 1.2rem !important;
      }

      .total-amount {
        float: right;
        font-weight: bold;
        color: black;
        text-decoration: underline;
      }

      .total-line {
        border-top: 1px solid $light-bg;
        border-bottom: 1px solid $light-bg;
        position: absolute;
        left: 0;
        right: 0;
        padding: 0.05rem 0.75rem;
        margin-bottom: 1rem;
      }
    }

    .discount-section {
      margin-top: 1.5rem;
    }

    // Baskets and checkout

    .mini-basket {
      transition: all linear 0.3s;
      opacity: 0.4;
    }

    .mini-basket:hover {
      opacity: 1;
    }

    .floating-basket,
    .basket-complete {
      .bskt-header {
        margin-top: 0;

        .bskt-header-text {
          //max-width: 68%;
        }

        .edit-bskt {
          font-size: 10px;
          font-weight: normal;
          padding: 0 0.5rem;
          border: 1px solid $lighter-grey;
        }

        .team-basket-item {
          font-weight: bold;

          .team-entry-no {
            font-size: 1em;
            font-weight: normal;
            display: inline;
            margin-top: 0;
            clear: left;
          }

          .team-entry-no:before {
            content: "(";
          }

          .team-entry-no:after {
            content: ")";
          }
        }
      }

      .team-basket-item {
        font-weight: bold;

        .team-entry-no {
          font-size: 0.9em;
          font-weight: normal;
          //display: block;
          margin-top: -0.1rem;
        }
      }

      .basket {
        //display: block;
        width: 100%;
        margin: 0em;
        background: "";
        box-shadow: none;
        line-height: 1.14285714em;
        border-radius: 0 0 $pub-bord-radius $pub-bord-radius;
        border: 1px solid $rn-dark;

        hr {
          width: 100%;
          display: block;
          border-top: 1px solid $rn-blue;
          margin: 0 auto;
        }

        .remove-from-bskt,
        .basket-actions {
          position: absolute;
          top: 0.35rem;
          right: 1.5rem;
        }

        .remove-from-bskt.remove-donation {
          display: inline-block;
          float: none;
          clear: both;
          //cursor: help;
          position: relative;
          max-width: 18px;
          //right: -1.88rem;
          max-height: 18px;
          padding: 0.15rem 0.1rem 0.1rem 0.1rem !important;
          top: -1.5px;
          margin-left: 1.25rem;
        }

        .bskt-button {
          //font-size: 0.9rem !important;
          //padding: 0.24rem !important;
          //margin-bottom: 0.2rem;
          //height: 20px !important;
          text-align: center;
        }

        .step .step_inner {
          width: 100%;
          display: block;

          a.info-popup {
            display: inline-block;
            vertical-align: top;
          }

          .price {
            float: right;
            font-weight: bold;
            padding-top: 0.2rem;
          }

          .requires_action {
            color: goldenrod;
          }

          .filled_in {
            color: yellowgreen;
          }

          i.check.icon.filled_in {
            margin-left: 0.5rem;
          }

          .filled_in:hover {
            text-decoration: underline;

            i.icon {
              text-decoration: none;
            }
          }
        }

        .step.single-ticket,
        .step.other-items,
        .step.other-items {
          max-height: 1.4rem;
          //color: $white;
          background: $lighter-grey;
          padding-top: 0.6rem !important;
          padding-bottom: 1.6rem !important;
          font-weight: bold;
          font-size: 0.9rem;
          border-bottom: 1px solid $rn-blue;

          i.users.icon {
            bottom: 0.15rem;
          }
        }

        .step.team-title:first-child {
          padding-top: 0.5rem !important;
          padding-bottom: 1.5rem !important;
        }

        .step.team-title.team-name {
          max-height: 100%;
          height: auto;
          //color: $white;
          background: $lighter-grey;
          padding-top: 0.6rem !important;
          padding-bottom: 1.6rem !important;
          font-weight: bold;
          font-size: 0.9rem;
          border-bottom: 1px solid $rn-blue;
          min-height: 3.2rem;

          i.users.icon {
            bottom: 0.15rem;
          }

          //max-height: 100%;
          height: auto !important;
          padding-bottom: 0.3rem !important;
          margin: 0;
          //min-height: 7rem;
          box-sizing: content-box;

          .item-desc {
            max-width: 44%;

            em {
              font-size: 0.95em;
            }
          }

          .team-price-labels {
            margin-top: 1.75rem;
            text-align: right;

            span {
              font-size: 1.1em;
            }
          }

          .team-subsidy {
            //top: -1.8rem;
            //margin-bottom: -1.6rem;
            display: block;
            width: 100%;
            clear: both;
            font-weight: normal !important;
            font-size: 1.1em;

            .details {
              float: left;
            }

            .price {
              float: right;
              font-weight: normal !important;
            }
          }

          /*small .details {
					opacity: 0.9;
					font-weight: normal !important;
					text-align: right;
				}*/
        }

        .step:first-child {
          padding: 1.14285714em 1.5em 0 1.5rem !important;
          border-radius: 0;
        }

        .close-team {
          //border-bottom: 1px solid $rn-blue;
        }

        .step:not(.team-title):not(.other-items) {
          min-height: 6rem;
        }

        .step.other-items {
          padding-top: 0.4rem !important;
          padding-bottom: 2rem !important;
        }

        .step {
          position: relative;
          //z-index: 0;
          display: block;
          margin: 0em 0em 0rem 0;
          //padding: 1em 1.5em 0 1.5em !important;
          background: #ffffff;
          color: rgba(0, 0, 0, 0.87);
          box-shadow: none;
          border-radius: 0em;
          transition: background-color 0.1s ease, opacity 0.1s ease,
            color 0.1s ease, box-shadow 0.1s ease;
          //padding: 1.14285714em 2em;
          border-right: none;
          border-top: 1px solid rgba(34, 36, 38, 0.15);
          border-bottom: none;
          clear: both;

          .step_inner {
            .item-desc {
              max-width: 75%;
              padding-top: 0.2rem;

              small {
                font-style: italic;
                display: inline-block;
                padding-bottom: 0.8rem;
              }
            }
          }

          .basket-item-actions {
            .bskt-button {
              margin-right: 0 !important;
            }
          }
        }

        .sub-step {
          position: static;
          float: left;
          padding: 0 1.9rem 0 1.5rem;
          //top: -1.8rem;
          //margin-bottom: -1.6rem;
          display: block;
          width: 100%;
          clear: both;

          .details {
            float: left;
          }

          .price {
            float: right;
          }
        }

        .donation-item {
          position: static;
          z-index: 4;
          float: left;
          //margin-top: -0.5rem;
          padding: 0 1.5rem 0 1.5rem;
          display: block;
          width: 100%;
          clear: both;
          //border-style: solid;
          //border-color: rgba(34, 36, 38, 0.15);
          //border-width: 0px 1px 1px 0px;

          .details {
            float: left;
          }

          .price {
            float: right;
          }
        }

        .donation-item.charity-donation-line {
          background: rgba(255, 182, 193, 0.08);
          padding-top: 0.4rem;
          padding-bottom: 0.05rem;

          .donation-price.price {
            //display: inline-block;
            //position: relative;
            //right: -1.5rem;
          }
        }

        /*.donation-item.charity-donation-line.moved, .donation-item.charity-donation-line.active-donation {
				margin-bottom: 0.5rem;
			}*/

        .donation-item.active-donation {
          background: #f3f4f5;
        }

        .sub-step.filled_in {
          display: none;
        }

        .active.step:not(.dropdown) {
          font-weight: bolder;
          cursor: auto;
          background: #f3f4f5;
          margin-bottom: 0;
          padding-bottom: 0 !important;
        }

        .active.sub-step {
          font-weight: normal !important;
        }

        .active.dropdown {
          background: none;
        }

        .active.step::before {
          display: block;
        }

        .step.active::before {
          background: #f3f4f5;
        }

        .step.active::after {
          display: none !important;
        }

        .step::before {
          top: 50%;
          left: -20px;
          border-width: 1px 0px 0px 1px;
        }

        /*.active.step::before {
			    display: block !important;

			    position: absolute;
			    z-index: 2;
			    content: '';
			    top: 30%;
			    left: 0%;
			    border: medium none;
			    background-color: #F3F4F5;
			    width: 1.14285714em;
			    height: 1.14285714em;
			    border-style: solid;
			    border-color: rgba(34, 36, 38, 0.15);
			    border-width: 1px 0px 0px 1px;
			    transition: background-color 0.1s ease, opacity 0.1s ease, color 0.1s ease, box-shadow 0.1s ease;
			    transform: translateY(50%) translateX(-50%) rotate(-45deg);
			}*/

        .step.has-donation {
          border-bottom: none;
          //margin-bottom: -0.3rem;
        }

        .step.has-donation::before {
          display: none;
        }

        .step.no-sub {
          margin-bottom: 0;
        }

        .bskt-total {
          background: none transparent;
          box-shadow: none;
          border: none;
          border-radius: 0px !important;
          padding: 1.14285714em 0.75rem 1.5rem 0.75rem;
          margin-bottom: 0em;
          margin-top: 1rem;
          position: static;
          border-radius: 0.28571429rem;
          //border-top: 1px solid $rn-blue;

          .discount-input {
            width: 60%;
          }
        }

        .apply-credit-btn {
          padding: 0.5rem 1rem;
          font-size: 1.25rem;
        }

        #btn_pay,
        #btn_pay_free.credit-order {
          padding-left: 1.5em !important;
          padding-right: 1.5em !important;
          background-color: #21ba45 !important;
          color: #ffffff;
          text-shadow: none;
          background-image: none;
          display: block;
          width: 100%;
          max-width: 274px;
          margin: 0 auto !important;
          font-size: 1.3rem;
          transition: all linear 0.2s;
          cursor: pointer;
          min-height: 3rem;
          outline: none;
          border: none;
          vertical-align: baseline;
          font-family: $font-family;
          margin: 0em 0.25em 0em 0em;
          text-transform: none;
          font-weight: bold;
          line-height: 1em;
          font-style: normal;
          text-align: center;
          text-decoration: none;
          border-radius: 0.28571429rem;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          -webkit-tap-highlight-color: transparent;
          animation: slideDown 0.7s $globalDelay + 0.35s $easeOutCust both,
            $globalDelay + 0.3s $easeInQuad both, blink 2.5s linear infinite;
        }

        #btn_pay:hover {
          background-color: #006400 !important;
        }
      }
    }

    .basket-complete {
      hr {
        display: none;
      }

      .checkout-total {
        color: $rn-dark;
        font-weight: 1.5em;

        .total-amount {
          float: none;
          text-decoration: none;
        }
      }

      .bskt-total {
        background: none transparent;
        box-shadow: none;
        border: none;
        border-radius: 0px;
        padding: 1rem 0.75rem;
      }

      .apply-credit-btn {
        padding: 0.75rem 1rem;
        font-size: 1rem;
      }

      #btn_pay,
      #btn_pay_free.credit-order {
        padding-left: 1.5em !important;
        padding-right: 1.5em !important;
        background-color: #21ba45 !important;
        color: #ffffff;
        text-shadow: none;
        background-image: none;
        display: block;
        width: 100%;
        max-width: 274px;
        margin: 0 auto !important;
        font-size: 1.3rem;
        transition: all linear 0.2s;
        cursor: pointer;
        min-height: 3rem;
        outline: none;
        border: none;
        vertical-align: baseline;
        font-family: $font-family;
        margin: 0em 0.25em 0em 0em;
        text-transform: none;
        font-weight: bold;
        line-height: 1em;
        font-style: normal;
        text-align: center;
        text-decoration: none;
        border-radius: 0.28571429rem;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-tap-highlight-color: transparent;
        animation: slideDown 0.7s $globalDelay + 0.35s $easeOutCust both,
          $globalDelay + 0.3s $easeInQuad both, blink 2.5s linear infinite;
      }

      #btn_pay:hover {
        background-color: #006400 !important;
      }

      .edit-basket-btn {
        font-size: 0.8rem;
      }
    }

    .pay-icons {
      max-width: 280px;
      margin: 1.8rem auto 0 auto;
      text-align: center;

      .pay-icon {
        margin: 0 0.7rem;
        display: inline-block;
      }

      .visa {
        max-width: 50px !important;
      }

      .mastercard {
        max-width: 40px !important;
      }

      .amex {
        max-width: 50px !important;
      }
    }

    .chkt-accordion {
      margin-top: 0;
      margin-bottom: 2rem;

      .title {
        padding: 12px 8px;
      }

      .chkt-login-blk {
        padding: 1.5em;
        position: relative;
        background: #ffffff;
        //box-shadow: 0px 1px 2px 0 rgba(34, 36, 38, 0.15);
        margin: 1rem 0em;
        padding: 1em 1em;
        border-radius: 0.28571429rem;
        border-width: 1px;
        border-style: solid;
      }
    }

    // Receipt Page

    .rn-banner {
      width: 60%;
      margin: 0 auto 2rem auto;
      border-radius: $pub-bord-radius;
      //border: 1px solid $mid-grey;
      //float: right;
      //padding: 2.5rem;
      //box-shadow: 0px 0px 10px $light-bg;

      img {
        width: 100%;
        border-radius: $pub-bord-radius;
      }
    }

    .payment-header {
      padding-top: 2rem;
      display: none;
    }

    .order-number {
      float: right;
      font-weight: normal;
      font-size: 1rem;
    }

    .receipt-message {
      display: block;
      min-height: 1em;
      margin: 1em 0em;
      background: #f8f8f9;
      padding: 1em 1.5em;
      line-height: 1.4285em;
      color: rgba(0, 0, 0, 0.87);
      transition: opacity 0.1s ease, color 0.1s ease, background 0.1s ease,
        box-shadow 0.1s ease;
      border-radius: 0.28571429rem;
      box-shadow: 0px 0px 0px 1px rgba(34, 36, 38, 0.22) inset,
        0px 0px 0px 0px transparent;
    }

    .receipt-table {
      width: 100%;

      td {
        /*border-bottom: 1px solid $lighter-grey;
				border-top: 1px solid $lighter-grey;*/
        padding: 0.8rem;
        border-bottom: 1px solid $lighter-grey;
      }

      td.receipt-row,
      > tbody:nth-child(1) > tr:nth-child(3) td {
        border-bottom: none;
      }

      tr td:first-child {
        width: 35%;
        font-weight: bold;
        background: $light-bg;
        border-right: $lighter-grey 1px solid;

        .basket {
          font-weight: normal;
        }
      }

      td.whitebg {
        max-height: 5px !important;
        padding: 0;
        border-bottom: 2px solid $mid-grey;
      }

      td.tot-divider {
        max-height: 5px !important;
        padding: 0;
        border-bottom: 2px solid $rn-blue;
      }

      tr:last-child:not(.receipt-totals) > .whitebg {
        display: none;
      }

      .receipt-totals {
        padding-top: 1rem;
        //border-bottom-left-radius: $pub-bord-radius;
        font-weight: bold;
        font-size: 1.3rem;

        td {
          padding-top: 1rem;
          border-top: 1px solid $dark-grey;
        }

        td:first-child {
          border-bottom-left-radius: $pub-bord-radius;
        }
      }
    }

    .bold {
      font-weight: bold;
    }

    ul.org-info {
      list-style: none;

      li {
        margin-bottom: 0.5rem;
        font-size: 1rem;
      }
    }

    .contact-button {
      margin-top: 3rem;
      text-align: center;

      a {
        border-radius: $pub-bord-radius;
        padding: 1.5rem;
        font-weight: bold;
        color: $white;
        text-align: center;
        font-size: 1.5rem;
        background: $rn-blue;
      }
    }
  }

  .checkout-page.reg-form {
    .basket {
      .ui.right.pointing.dropdown > .menu {
        top: -12px !important;
        right: 1.25rem;
      }

      .entry_head .menu {
        opacity: 1 !important;
      }

      .entry_head,
      .team_head,
      .series_head,
      .team_foot,
      .series_foot,
      .others_head,
      .item_row,
      .entry_head:not(.active) ~ .item_row {
        opacity: 0.6;
      }

      .active.entry_head,
      .active.team_head,
      .active.series_head,
      .active.others_head,
      .active ~ .item_row {
        opacity: 1;
      }
    }

    .active-item {
      box-shadow: 1px 5px 8px 4px $mid-grey, -1px -2px 8px 4px $lighter-grey;
      opacity: 1 !important;
      border-left: 1px solid;
      border-right: 1px solid;
      width: 102%;
      margin: auto auto;
      background: $white;
      position: relative;
      right: 1%;
      z-index: 4;

      .entry_head,
      .team_head,
      .team_foot,
      .series_head,
      .series_foot,
      .others_head,
      .item_row {
        opacity: 1 !important;
      }
    }
  }

  main.series-page {
    button,
    .btn {
      cursor: pointer;
    }

    .buy-btn {
      form {
        .btn {
          height: 3rem;
          font-size: 1rem;
        }
      }
    }
  }

  main.checkout-page {
    min-height: 950px;

    .mobile-login-accordion {
      width: 100%;
    }
  }

  // Additional basket styles for receipt page

  .main.receipt-page {
    .basket {
      border: none;
      padding: 0;
    }
  }

  td.receipt-row {
    padding: 0 !important;
  }

  // Footer

  .grecaptcha-badge {
    z-index: 10;
  }

  /*
	footer positioning fix - SAAS-3607
	applies to all pages
	resources/views/common/public/layout.blade.php
	*/

  /* V2 - sticky to bottom if page is shorter than window height - class toggled by windowFooterPositioning() in "mattzb.js"   */
  footer.public-footer.footer_sticky {
    position: absolute;
    bottom: 0px;
  }

  footer.public-footer {
    margin-top: auto;
    background: $rn-dark;
    width: 100%;
    height: 50px;
    //position: relative;
    z-index: 2;
    text-align: center;
    vertical-align: middle;
    display: block;
    padding-top: 1rem;
    /*position: absolute;
		bottom: 0;*/

    .footer-content {
      color: $rn-dark;
      vertical-align: middle;
      max-width: 960px;
      width: 100%;
      //padding-bottom: 1rem;
      display: block;
      text-align: center;
      margin: 0 auto;
      font-size: 1rem;

      a {
        text-decoration: none;
      }

      a:hover {
        text-decoration: underline;
      }
    }
  }

  .ol-zoom-in,
  .ol-zoom-out {
    padding: 0.5rem 1rem 1rem 0.5rem;
    font-size: 1rem;
  }
}

// RACECHECK widget positioning
body.public.down {
  #racecheck-widget {
    bottom: 2.8rem !important;
  }
}

// Webview lightbox styling

a.lightbox img {
  height: 60px;
  width: 60px;
  border: 3px solid white;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
}

/* Styles the lightbox, removes it from sight and adds the fade-in transition */
.lightbox-box {
  padding: 0;
  margin: 0;
  text-align: left;
}
.lightbox-target {
  position: fixed;
  left: 0%;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  opacity: 0;
  -webkit-transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  -o-transition: opacity 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out;
  overflow: hidden;
  z-index: 100;
}

/* Styles the lightbox image, centers it vertically and horizontally, adds the zoom-in transition and makes it responsive using a combination of margin and absolute positioning */

.lightbox-target img {
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-height: 0%;
  max-width: 0%;
  border: 3px solid white;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  z-index: 100;
}

/* Styles the close link, adds the slide down transition */

a.lightbox-close {
  display: block;
  width: 50px;
  height: 50px;
  box-sizing: border-box;
  background: white;
  color: black;
  text-decoration: none;
  position: absolute;
  top: -80px;
  right: 0;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  z-index: 101;
}

/* Provides part of the "X" to eliminate an image from the close link */

a.lightbox-close:before {
  content: "";
  display: block;
  height: 30px;
  width: 1px;
  background: black;
  position: absolute;
  left: 26px;
  top: 10px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* Provides part of the "X" to eliminate an image from the close link */

a.lightbox-close:after {
  content: "";
  display: block;
  height: 30px;
  width: 1px;
  background: black;
  position: absolute;
  left: 26px;
  top: 10px;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

/* Uses the :target pseudo-class to perform the animations upon clicking the .lightbox-target anchor */

.lightbox-target:target {
  opacity: 1;
  top: 0;
  bottom: 0;
}

.lightbox-target:target img {
  max-height: 100%;
  max-width: 100%;
}

.lightbox-target:target a.lightbox-close {
  top: 0px;
}

// Popup series list styling

ul.series-popup-list {
  list-style: square;
  margin-top: 0.5rem;

  li {
    margin-left: 0.8rem;
  }
}

#series_confirm_modal {
  .content {
    p {
      text-align: left !important;
    }
  }
}

// Public Media Queries & Responsive Styles

/*@media only screen and (max-width: 1024px) and (min-width: 768px) {

	body.public {

		.main {

			.merch-items {

				.merch-item {
					width: 29%;
				}
			}
		}
	}



}*/

@media only screen and (max-width: 1024px) {
  body.public {
    .message-banner {
      .message-container {
        padding: 0 1rem;
      }
    }

    .main {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    .event-popup-container {
      overflow-y: auto;
      max-height: 500px;
    }

    .ui.popup.wide {
      min-width: 100%;
      border-style: solid;
      border-width: 1px;
      left: 0 !important;
    }

    .chkt-form-container {
      .refund-protect-field {
        .radios {
          label {
            display: block;

            input {
              display: inline-block;
              float: none;
              width: 5%;
            }

            b,
            .b {
              display: inline-block;
              float: right;
              width: 90%;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 990px) {
  body.public {
    .main {
      .one-col {
        width: 31%;
      }

      .two-col {
        width: 64%;
      }

      .cols {
      }

      .add-tickets {
        .content {
        }

        .ticket-items {
          .item-title {
            max-width: 70%;
          }
        }
      }
    }
  }

  .ui.modal.accom-modal {
    height: 70vh !important;

    .content {
      height: 80%;

      iframe {
        height: 100% !important;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  // Register button that slides in at the bottom of the event page

  .mobile-cta-footer {
    position: relative;
    width: 100%;
    height: 50px;
    padding: 0.5rem 3rem;
    background: rgba(228, 228, 228, 0.95);
    display: none;
    text-align: center;
  }

  .mobile-cta-footer.series-info {
    padding: 0.5rem 1rem;
    height: auto;
    text-align: left;
    column-gap: 1rem;

    .series-progress {
      max-width: 48%;
      font-size: 1rem;
    }

    .basket-menu.reg-form-button {
      min-width: 147px;
    }
  }

  .mobile-cta-footer.clone {
    position: fixed;
    bottom: -65px;
    left: 0;
    right: 0;
    z-index: 999;
    transition: 0.2s bottom cubic-bezier(0.3, 0.73, 0.3, 0.74);
    display: flex;
    align-items: center;
    column-gap: 2rem;

    .basket-menu {
      .item_count {
        background: $rn-orange;
        color: $white;
        //padding: 0.1rem !important;
      }
    }

    .reg-form-button {
      margin: 0 auto;
    }
  }

  .register-btn {
    min-width: 100px;
    width: 60%;
    display: block;
    margin: 0 auto;
  }

  #mobile-basket {
    .border-wrapped-header,
    .basket {
      border-radius: 0;
      border: none;
    }

    .main-bskt {
      display: block;
    }

    .close-basket {
      text-align: center;
      color: $white;
      font-size: 0.75rem;
      font-weight: bold;
      padding: 0.05rem;
      background: #ba2222;
      border-radius: $border-radius-sm;
      width: 110px;
      margin: 0 auto 1rem auto;
      position: relative;
      top: 0.5rem;
      cursor: pointer;
    }

    .no-items {
      text-align: center;
      margin-top: 2rem;
    }
  }

  #mobile-menu {
    .mobile-nav {
      .item:not(a.item) {
        display: none;
      }
    }

    .close-menu {
      text-align: center;
      color: $white;
      font-size: 0.75rem;
      font-weight: bold;
      padding: 0.05rem;
      background: #ba2222;
      border-radius: $border-radius-sm;
      width: 110px;
      margin: 0 auto 0.5rem auto;
      position: relative;
      top: 0.5rem;
      cursor: pointer;
    }
  }

  /* Basket - Hide toast messages on smaller screens, as it ends up covering other elements e.g. basket */
  body.public {
    #toast-container {
      display: none;
    }
  }

  body.down .mobile-cta-footer.clone {
    bottom: 0;
  }

  .discount-code-header {
    margin-top: 0 !important;
  }

  .chkt-form-container {
    margin-bottom: 2rem;
  }

  #mobile_discount_code {
    margin-top: 0;
    margin-bottom: 1.5rem;
  }

  body.public {
    header.public-header {
      position: static !important;
      //min-height: 3.5rem;
      width: auto;
      height: auto;
      box-sizing: content-box;
      padding: 0 2rem 1rem 2rem;
      min-height: auto;

      .org-title {
        max-width: 70%;
        float: none;
        margin-right: 3%;
        margin-top: 14px;
      }

      .powered-by {
        max-width: 25%;
        //float: none;

        img {
          width: 100%;
        }
      }
    }

    main.public-container.public-entry {
      .org-intro {
        margin-top: 2rem !important;
      }
    }

    main.public-container.leaderboard {
      .public-accordion {
        margin-bottom: 2rem;
      }
    }

    main.series-page {
      .mobile-login-accordion {
        margin-bottom: 2rem !important;
      }
    }

    .main {
      padding: 1rem 0.75rem;

      .org-intro {
        margin-top: 0 !important;
      }

      .one-col,
      .two-col,
      .three-col {
        //display: block;
        width: 100%;
        padding: 0;
        float: none;
      }

      .two-col:first-child {
        //padding-top: 2rem;
      }

      .cols:first-child {
        //margin-top: 2rem;
      }

      .border-wrapped {
        padding: 1.5rem 0.75rem !important;
      }

      .about-org.border-wrapped-header {
        margin-top: 2rem !important;
      }

      .cols {
        .col:last-child {
          //margin-bottom: 2rem;
        }
      }

      .promo-img {
        margin-top: 2rem;
      }

      .add-tickets {
        .content {
        }

        .ticket-items {
          .item-title {
            //max-width: 100%;
          }
        }
      }

      .main-bskt {
        display: none;
      }

      .floating-basket {
        .basket {
          margin: 0;

          div.step.active::before {
            display: none;
          }
        }
      }

      .basket {
        .active-item::before {
          display: none;
        }
      }

      .bskt-header {
        //margin-top: 2rem;
      }

      .chkt-sign-in {
        margin-top: 2rem;
        margin-bottom: 0;
      }

      .chkt-accordion {
        margin-top: 2rem;
      }

      .chkt-form-container {
        .public-reg-form {
          position: static;

          .full-width-field {
            margin: 1.5rem auto;
          }

          .two-fields {
            .half-width-field {
              width: 100%;
              display: block;
              margin: 1.5rem auto;
            }

            .half-width-field:last-child {
              float: none;
            }
          }

          .radios {
            div {
              width: 100%;
            }
          }

          .pub-action-input {
            padding: 1rem;
            border: 1px solid rgba(34, 36, 38, 0.15);
            border-radius: $pub-bord-radius;

            input[type="text"],
            select,
            button {
              display: block;
              width: 100%;
              margin: 0 0 0.5rem 0;
            }

            input[type="text"] {
              border-top-right-radius: $pub-bord-radius;
              border-bottom-right-radius: $pub-bord-radius;
            }

            select {
              border-radius: $pub-bord-radius;
            }

            button {
              border-top-left-radius: $pub-bord-radius;
              border-bottom-left-radius: $pub-bord-radius;
            }
          }
        }
      }

      h3.fundraising-header {
        margin: -2.5rem auto 1rem auto;
      }

      .sg-nudge-block.persist {
        padding: 0.5rem 0.75rem 1.5rem 0.75rem;
      }

      /*.sg-nudge-block h3:nth-child(2) {
   				 margin-top: -0.5rem;
			}*/
    }

    .receipt-page {
      .receipt-message {
        margin: 0 auto;
      }

      .your-receipt {
        margin-top: 0;
      }
    }

    footer.public-footer {
      position: relative;
      z-index: 1;
      padding-bottom: 60px;
    }
  }
}

@media only screen and (max-width: 680px) {
  #mobile-menu {
    .mobile-nav {
      .step {
        a {
          color: #4183c4 !important;
        }
      }
    }

    .mobile-lang-selector {
      display: block;
      margin: 0 auto;
      width: 60%;
    }
  }

  /*html:not(i.icon) {
		font-size: 16px !important;
	}*/

  body.public {
    header.public-header {
      .public-container {
        column-gap: 1%;

        .mobile-main-menu {
          display: inline-flex;
          align-self: center;
          margin-top: 8px;
          /*margin-right: -1.25rem;*/
          margin-left: -0.35rem;
        }

        h4 {
          .event-date {
            display: none;
          }
        }
      }
    }

    .main {
      .register-breadcrumb {
        display: none;
      }

      .add-tickets {
        .wlist {
          width: 100%;
          position: relative;
          top: -2rem;
          //clear: both;
        }

        .wlist-btn {
          //float: none;
          position: relative;
          cursor: pointer;
          //top: 3rem;
          //width: 50%;
        }
      }

      .series-list {
        li {
          text-align: center;

          .float-left,
          .float-right {
            float: none;
            display: block;
          }

          .less-top-padding {
            max-width: 100% !important;
            width: 100%;
          }

          .btn {
            max-width: 200px;
            margin: 0.5rem auto;
          }
        }
      }

      .donation-nudge-giftaid {
        padding-top: 0;
      }

      .custom-fields-formv2 {
        .custom-field.half-width-field {
          grid-column-end: span 2;
        }
      }

      .series-list {
      }

      .terms-container {
        label {
          display: inline-block;
          max-width: 85%;
          vertical-align: top;
        }
      }

      .chkt-form-container
        .custom-fields-form
        [data-fieldtype="checkbox"]
        label
        span {
        width: 85%;
      }

      .series-total {
        border-top: 1px solid $lighter-grey;
        padding-top: 1rem;
        width: 100%;

        .buy-btn {
          width: 100%;

          .btn {
            width: 100%;
          }
        }
      }

      .chkt-form-container {
        .sg-nudge-block {
          .relative {
            background: transparent;
            position: static;

            select {
              -webkit-appearance: menulist;
              -moz-appearance: auto;
              appearance: auto;
              background: $white;

              &,
              &:hover,
              &:focus,
              &:active,
              &:blur {
                border: 1px solid $lighter-grey;
              }
            }

            i.not-sui-select-icon {
              display: none;
            }
          }

          .relative:last-child {
            margin-bottom: 2rem;
          }

          .relative.donation-nudge-custom {
            position: relative !important;
          }
        }

        .refund-protect-field {
          .radios {
            div {
              margin-right: 0;
            }

            label {
              b,
              .b {
                width: 88%;
              }
            }
          }
        }
      }

      .merch-items {
        li.merch-item {
          width: 32%;
          margin: 0 auto 0.5rem auto;
        }
      }
    }

    footer.public-footer {
      height: auto;
    }
  }
}

@media only screen and (max-width: 580px) {
  body.public {
    .main {
      .add-tickets {
        .content {
          padding: 0.5rem !important;
        }

        .ticket-items:first-child {
          border-top: none !important;
          padding-top: 0;
        }

        .ticket-row {
          margin-top: 1.5rem;

          .ticket-items {
            display: flex !important;
            display: -webkit-flex;
            flex-direction: column;
            text-align: center;
            padding: 0.8rem 0 0 0;
            margin: 0;
            border-top: 1px solid $mid-grey;

            .price-title {
              margin-bottom: 0.5rem;
              font-size: 1.4rem;
              float: none !important;
              /*background: $lighter-grey;
              border-radius: 3px;*/
            }

            .item-title {
              max-width: 100%;
              margin-bottom: 0;

              strong {
                hyphens: none;
                display: inline;
                max-width: 84%;
                vertical-align: text-top;
              }

              /*small {
                display: inline-block;
                max-width: 80%;
              }*/
            }

            .divider {
              //display: none;
              margin: 0;
            }

            .wlist {
              top: 0;

              .price-title {
                font-size: 16px;
              }
            }

            .wlist-btn {
              float: none;
              top: 0;
              margin-bottom: 2rem;
              display: inline-block !important;
              width: 25%;
              min-width: 112px;
              cursor: pointer;
            }

            .buy-btn,
            .event-info-btn {
              margin-top: 0.5rem;
              width: 100%;
              text-align: center;

              button {
                width: 25%;
                min-width: 100px;
                height: 1.8rem;
              }
            }
          }

          hr {
            display: none;
          }

          .ticket-items:first-child {
            border-top: none !important;
            padding-top: 0;
          }

          .buy-btn,
          .event-info-btn {
            margin-top: 0.5rem;
            width: 100%;
            text-align: center;

            button {
              width: 25%;
              min-width: 100px;
            }
          }

          .event-info-btn {
            width: 50%;
            display: block;
            margin: 0.5rem auto;
          }
        }

        .ballot-wrapper {
          margin-top: 1.5rem;
        }
      }

      .basket.receipt-items {
        .entry_head {
          flex-wrap: wrap-reverse;

          /*> div {
						width: 100%;
					}*/

          .step-accom {
            width: 100%;
            max-width: 100%;
            float: none;
            padding: 0.25rem 3rem;
            margin-bottom: 1rem !important;

            .accom-btn {
              max-width: 180px;
            }
          }
        }
      }

      #submitbutton {
        width: 80%;
        margin: 1rem auto;
        float: none;
        display: block;
      }
    }

    main.public-container.public-entry {
      .centered {
        .inline-blk {
          display: block;
          margin-bottom: 0.5rem;

          input,
          select,
          button {
            width: 100%;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 420px) {
  body.public {
    header.public-header {
      padding: 0 0.5rem 0.5rem 0.5rem;

      .org-title {
        max-width: 60%;
        float: none;
        margin-right: 3%;
        margin-top: 8px;
        vertical-align: top !important;

        h4 {
          display: inline-block;
          vertical-align: bottom;
        }
      }

      .powered-by {
        max-width: 30%;
        float: right;
        vertical-align: top !important;
        padding-right: 0.5rem;

        img {
          width: 100%;
        }
      }
    }

    .main {
      padding: 0rem 0rem 2rem 0rem;

      h2 {
        font-size: 1.2rem !important;
      }

      .border-wrapped {
        padding: 0.75rem !important;
      }

      .checkout-btn {
        width: 100%;
        display: block;
        text-align: center;
      }

      .inline-blk:not(div.title):not(.price):not(.arrow):not(.item-desc):not(.merch-item) {
        display: block;
      }

      .register-breadcrumb {
        .bread-item,
        .arrow {
          display: inline-block;
        }
      }

      .half-width-field {
        width: 100%;
      }

      .remove-btn {
        display: inline-block !important;
      }

      .border-wrapped {
        padding: 0.5rem;
      }

      .sg-logo {
        width: 130px;
        max-width: 130px;
        min-width: 130px;
      }

      .mask-block {
        max-width: 126px !important;
      }

      img.sg-logo-img {
        width: 25%;
        max-width: 30% !important;
        position: relative;
        z-index: 4;
        top: -2.2rem;
        left: 30%;
        float: right;
      }

      /*.sg-nudge-block h3:nth-child(2) {
        margin-top: 0.5rem;
      }*/

      .basket-container {
        .basket-item {
          .basket-event-item {
            .title {
              display: inline-block !important;
            }
          }
        }
      }

      .chkt-form-container {
        .rp-container {
          padding: 1rem 1rem !important;
        }

        .refund-protect-field {
          .radios {
            div {
              input {
                margin-left: 0.375rem;
              }
            }
          }
        }
      }

      //.chkt-form-container .refund-protect-field .radios div input

      .step .step_inner {
        width: 100%;
        display: block;

        .price {
          float: right;
          font-weight: bold;
          position: relative;
          top: -1.2rem;
        }

        .requires_action {
          color: goldenrod;
        }

        .filled_in {
          color: yellowgreen;
        }

        i.check.icon.filled_in {
          display: none;
        }
      }

      .chkt-form-container {
        .required-fields {
          top: 0;
        }
      }

      /*.add-tickets {

				.ticket-items {
					text-align: left;
					padding: 0;
					margin: 0;

					.item-title {
						max-width: 65%;
					}
				}

				.buy-btn, .event-info-btn {
					margin-top: 0.5rem;
					width: 100%;
					text-align: center;

					button {
						width: 100%;
					}
				}
			}*/

      .results-table {
        tbody {
          td:first-child {
            width: 50%;
            //border-right: 1px solid $mid-grey;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 400px) {
  body.public .main {
    .content--merch-holder {
      padding: 0.75rem 0 !important;

      .merch-wrapper {
        text-align: center;

        .merch-item.inline-blk {
          display: inline-block !important;
          max-width: 170px;
        }
      }
    }
  }
}

@media only screen and (max-width: 360px) {
  body.public {
    .main {
      img.sg-logo-img {
        left: 32%;
      }
    }

    .register-btn {
      font-size: 4vw;
    }
  }
}

@media only screen and (max-width: 320px) {
  body.public {
    header.public-header {
      .public-container {
        display: flex;
        display: -webkit-flex;
        justify-content: space-between;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        column-gap: 1%;
        align-items: stretch;
      }
    }

    .main {
      img.sg-logo-img {
        left: 35%;
      }

      .public-accordion {
        .title {
          font-size: 1rem;
        }
      }

      .chkt-form-container {
        .refund-protect-field {
          .radios {
            div {
              margin-right: 0;
            }

            label {
              b,
              .b {
                width: 80%;
              }
            }
          }
        }
      }

      .floating-basket .basket,
      .basket-complete .basket {
        #btn_pay {
          //width: 220px !important;
        }
      }
    }
  }
}
